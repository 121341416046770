import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Slider from 'react-slick';
import {
	Grid,
	Typography,
	Theme,
	CardMedia,
	Box,
	AvatarGroup,
	Avatar,
	Skeleton,
} from '@mui/material';
import { Button } from '@/components';
import { useArticlesService } from '@/store/services/article.service';
import { ArticleType } from '@/utils/types';

const styles = {
	container: {
		overflow: 'hidden',
		border: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
		borderRadius: '4px',
		flexWrap: 'nowrap',
	},
	textContainer: {
		padding: 3,
		height: '76px',
		backgroundColor: (theme: Theme) => theme.palette.common.white,
		borderBottom: (theme: Theme) => `1px solid ${theme.borderColor.searchField}`,
	},
	textPrimary: { color: '#0D1D24', fontSize: '18px', lineHeight: '21.6px' },
	card: {
		position: 'relative',
		padding: 3,
	},
	cardInfo: {
		padding: '10px 0px 0px 10px',
		position: 'absolute',
		top: 0,
		left: 0,
		alignItems: 'center',
	},
	avatarGroup: {
		justifyContent: 'flex-end',
	},
	cardAvatarText: {
		fontSize: '14px',
		lineHeight: '21px',
		color: (theme: Theme) => theme.palette.common.white,
	},
	category: {
		padding: '2px 0px 2px 10px',
		backgroundColor: (theme: Theme) => theme.borderColor.searchField,
		borderRadius: '20px 0px 0px 20px',
		color: 'text.secondary',
		fontSize: '12px',
		lineHeight: '14.4px',
		textAlign: 'right',
	},
	cardDetailPrimaryText: {
		fontSize: '24px',
		lineHeight: '28.8px',
		fontWeight: 600,
		color: '#0D1D24',
	},
	cardDetailSecondaryText: {
		fontSize: '18px',
		lineHeight: '21.6px',
		color: (theme: Theme) => theme.textColor.primary,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		display: '-webkit-box',
		WebkitLineClamp: '3',
		WebkitBoxOrient: 'vertical',
	},
	btn: {
		borderRadius: '4px',
		height: '26px',
		fontSize: '13px',
		lineHeight: '15.6px',
		fontWeight: 600,
	},
};

const settings = {
	dots: true,
	arrows: false,
	infinite: true,
	autoplay: true,
	autoplaySpeed: 5000,
	slidesToShow: 1,
	slidesToScroll: 1,
	customPaging: () => (
		<Box
			className="custom-dot"
			sx={{
				width: '12px',
				height: '12px',
				backgroundColor: '#B6C9D3',
				padding: '5px',
				borderRadius: '50%',
			}}
		/>
	),
};

const RecommendedResources = () => {
	const router = useRouter();
	const [articles, setArticles] = useState<ArticleType[]>([]);
	const { getArticles: getArticlesService } = useArticlesService();

	const getArticles = useCallback(async () => {
		const result = await getArticlesService({ take: 4 });
		setArticles(result.data);
	}, [getArticlesService]);

	useEffect(() => {
		getArticles();
	}, [getArticles]);

	return (
		<>
			<Head>
				<link
					rel="stylesheet"
					type="text/css"
					charSet="UTF-8"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
				/>
			</Head>
			<Grid container direction="column" sx={{ ...styles.container, height: '100%' }}>
				<Grid item>
					<Grid container gap={'10px'} alignItems="center" sx={styles.textContainer}>
						<Typography sx={styles.textPrimary}>Recommended Resources</Typography>
					</Grid>
				</Grid>
				<Grid item height="calc(100% - 71px)">
					<Slider {...settings}>
						{articles.map((article, index) => (
							<Box sx={styles.card} key={index}>
								<Box position="relative">
									{article.heroImage ? (
										<CardMedia
											component="img"
											alt="card-image"
											sx={{ height: 340, borderRadius: '8px', overflow: 'hidden' }}
											src={`${article.heroImage}?auto=compress&cs=tinysrgb&fit=crop&h=600&w=800`}
										/>
									) : (
										<Skeleton variant="rectangular" width={'100%'} height={340} />
									)}
									<Grid container sx={styles.cardInfo} justifyContent="space-between">
										<Grid item>
											<Grid container alignItems="center">
												<Grid item>
													<AvatarGroup sx={styles.avatarGroup}>
														<Avatar
															alt="Remy Sharp"
															src=""
															sx={{ height: '32px', width: '32px' }}
														>
															{article?.author?.charAt(0)}
														</Avatar>
													</AvatarGroup>
												</Grid>
												<Grid item pl={1}>
													<Typography sx={styles.cardAvatarText}>
														{article.author}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
										{/* <Grid item>
										<Grid container justifyContent="flex-end">
											<Box sx={styles.category}>Audiogram</Box>
										</Grid>
									</Grid> */}
									</Grid>
								</Box>

								<Grid container direction="column" pt={2} gap={1}>
									<Grid item>
										<Typography sx={styles.cardDetailPrimaryText}>
											{article.title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography sx={styles.cardDetailSecondaryText}>
											{article.summary}
										</Typography>
									</Grid>
									<Grid item>
										<Button
											text="Read More"
											customStyles={styles.btn}
											onClick={() => router.push(`library/articles/${article.id}`)}
										/>
									</Grid>
								</Grid>
							</Box>
						))}
					</Slider>
				</Grid>
			</Grid>
		</>
	);
};

export default RecommendedResources;
